<template>
  <navigation index="sysRepairManage" ref="navigations">
    <div class="topnavigation">
      <div style="display: flex; gap: 10px; align-items: center; height: 40px">
        <el-input
          v-model="repairListPadding.Search"
          :placeholder="tools.GetLanguageValue('web.请输入')"
          @input="handleQuery"
          style="width: 200px"
          :prefix-icon="Search"
        ></el-input>
        <el-button @click="AdminLogin">
          {{ tools.GetLanguageValue("web.登录") }}</el-button
        >
      </div>
    </div>

    <div style="width: 100%">
      <el-table
        class="dataTable"
        border
        @cell-click="handleCellClick"
        :data="repairList"
        style="width: 100%"
        :height="ContentHeight + 'px'"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column
          prop="RepairNo"
          width="160"
          :label="tools.GetLanguageValue('web.单号')"
        >
          <template #default="{ row }">
            <span v-if="row.IsReworking"> {{ row.RepairNo }}(返修) </span>
            <span v-if="!row.IsReworking">
              {{ row.RepairNo }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          width="160"
          :label="tools.GetLanguageValue('web.状态')"
        >
          <template #default="{ row }">
            <span v-if="row.IsDelete" style="color: red; font-size: 18pt">
              {{ tools.GetLanguageValue("web.删除") }}</span
            >
          </template>
        </el-table-column>

        <el-table-column
          prop="ClientName"
          width="120"
          :label="tools.GetLanguageValue('web.客户姓名')"
        ></el-table-column>
        <el-table-column
          prop="BrandAndModel"
          :label="tools.GetLanguageValue('web.维修品牌')"
          width="150"
        >
          <template #default="{ row }">
            <el-tag
              style="margin: 3px"
              v-if="row.BrandAndModel != null && row.BrandAndModel.length > 0"
              type="info"
              >{{ row.BrandAndModel }}</el-tag
            >
            <el-tag
              style="margin: 3px"
              v-if="row.ModelName != null && row.ModelName.length > 0"
              >{{ row.ModelName }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="RepairProblems"
          :label="tools.GetLanguageValue('web.维修问题/服务')"
          width="300"
        >
          <template #default="{ row }">
            <el-tag
              style="margin: 3px"
              v-for="item in row.RepairProblems"
              :key="item"
            >
              {{ item }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalPrice"
          label="维修总金额"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="ImelNumber"
          label="IMEI"
          width="150"
        ></el-table-column>

        <el-table-column
          prop="RepairOperatorName"
          :label="tools.GetLanguageValue('web.创建人')"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="RepairStartTimeFormat"
          :label="tools.GetLanguageValue('web.维修时间')"
          width="170"
        ></el-table-column>
        <el-table-column
          prop="WarrantyStartTimeFormat"
          :label="tools.GetLanguageValue('web.保修开始日期')"
          width="170"
        ></el-table-column>

        <el-table-column
          prop="Repairstatus"
          :label="tools.GetLanguageValue('web.状态')"
          fixed="right"
          width="150"
        >
          <template #default="{ row }">
            <div style="display: flex; gap: 5px">
              <el-select
                v-model="row.Repairstatus"
                @change="changeStatus(row)"
                style="width: 120px"
              >
                <el-option
                  v-for="item in repairstatusOptions"
                  :key="item.Value"
                  :label="item.Value"
                  :value="item.Key"
                >
                  <span
                    style="
                      color: var(--el-text-color-secondary);
                      font-size: 17px;
                    "
                  >
                    {{ item.Value }}</span
                  >
                </el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center; height: 50px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="repairListPadding.Page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="repairListPadding.Size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="repairListPadding.Count"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      v-model="AdminPasswordVisible"
      :title="tools.GetLanguageValue('web.登录')"
      width="500"
      :before-close="handleClose"
    >
    <div style="margin: 5px">
        <el-input
          v-model="Account" 
          :placeholder="tools.GetLanguageValue('web.请输入账号')"
        ></el-input>
      </div>
      <div style="margin: 5px">
        <el-input
          v-model="AdminPassword"
          type="password"
          :placeholder="tools.GetLanguageValue('web.请输入密码')"
        ></el-input>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="AdminPasswordVisible = false">
            {{ tools.GetLanguageValue('web.关闭') }}
          </el-button>
          <el-button type="primary" @click="VerificationAdminPassword">
             {{ tools.GetLanguageValue('web.确定') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </navigation>
</template>
  <script setup>
import { useRouter } from "vue-router";
import axios from "../../../commons/AxiosMethod.js";
import navigation from "../../../components/Modules/Navigation.vue";
import { Search, Close, Refresh, Message } from "@element-plus/icons-vue";
import { onBeforeMount, onMounted, ref } from "vue";
import tools from "../../../commons/tools.js";
import { ElMessage, ElMessageBox } from "element-plus";

const clientWidth = document.body.clientWidth;
const clientHeight = document.documentElement.clientHeight;

const queryWord = ref("");
// 分页信息
const padding = ref({
  Page: 1,
  Size: 10,
  Count: 0,
});


const router = useRouter();

const selectRepairStatusValue = ref();
const queryAll = ref("all");
const needClear = ref(false);
const repairstatusOptions = ref([]); // 维修状态数据
const repairReserveNum = ref(0); // 预定数量
const repairPendingNum = ref(0); // 待开始数量
const repairRepairingNum = ref(0); // 维修中数量

// const options = reactive(optionsMap.value)
const repairData = ref([]);
const navigations = ref();
const ContentHeight = ref(document.documentElement.clientHeight - 130);

// 监听页面大小变化事件
const getWindowInfo = () => {
  const windowInfo = {
    width: window.innerWidth,
    hight: window.innerHeight,
  };
  navigations.value.onresize();
  ContentHeight.value = windowInfo.hight - 130;
};
window.addEventListener("resize", getWindowInfo);

//----------------------重构------------------------

const RepairSatusCount = ref({});

// 页面加载
onMounted(() => {
  CheckAdminPassword();
  // getRepairList();
  navigations.value.onresize();
});


const AdminPasswordVisible = ref(false);
const Account = ref('');
const AdminPassword = ref("");
const AdminLogin = () => {
  AdminPasswordVisible.value = true;
};
//验证密码获取token
const VerificationAdminPassword = () => {
  axios.apiMethod(
    "/stores/System/VerificationAdminPassword",
    "post",
    { Password: AdminPassword.value ,Account: Account.value},
    (result) => {
      if (result.Data.IsSuccess) {
        sessionStorage.setItem("VerificationAdminPassword", result.Data.Token);
        sessionStorage.setItem("VerificationAdminAccount",  Account.value);
        //加载数据
        getRepairList();
        AdminPasswordVisible.value = false;
      } else {
        ElMessage({
          message: tools.GetLanguageValue("web.密码错误"),
          type: "error",
        });
      }
    }
  );
};

//检查admin密码，检查完成后才可以访问本页面
const CheckAdminPassword = () => {
  var token = sessionStorage.getItem("VerificationAdminPassword");
  var account = sessionStorage.getItem("VerificationAdminAccount");
  axios.apiMethod(
    "/stores/System/CheckAdminPassword",
    "post",
    { Password: token ,Account: account},
    (result) => {
      if (!result.Data.IsSuccess) {
        AdminPasswordVisible.value = true;
      } else {
        //加载数据
        getRepairList();
      }
    }
  );
};

// 行点击跳转到详情页
const handleCellClick = (row, column, cell, event) => {
  if (cell.cellIndex > 8) return;
  // 处理行数据，然后跳转到repairDetail页面

  router.push({
    name: "adminRepairDetail",
    query: { id: row.RepairId, itemId: row.Id },
  });
};

// 选择改变
const changeStatus = (row) => {
  // 改变之后调用更新方法
  axios.apiMethod(
    "/stores/System/UpdateRepairMobileStatus",
    "post",
    row,
    (result) => {
      if (result.Data.IsSuccess) {
        ElMessage({
          message: tools.GetLanguageValue("web.操作成功"),
          type: "success",
        });
      } else {
        ElMessage({
          message: result.Data.Msg,
          type: "error",
        });
      }
      getRepairList();
    }
  );
};

const ChangeRepairstatus = () => {
  getRepairList();
};

const repairListPadding = ref({
  Where: {
    Repairstatus: [],
  },
  Search: "",
  Page: 1,
  Size: 30,
});

const repairList = ref([]);

// 获取维修单列表
const getRepairList = () => {
  axios.apiMethod(
    "/stores/System/GetRepairIncludeDeleteList",
    "post",
    repairListPadding.value,
    (result) => {
      repairList.value = result.Data.Datas;
      repairListPadding.value.Page = result.Data.Page;
      repairListPadding.value.Size = result.Data.Size;
      repairListPadding.value.Count = result.Data.Count;
    }
  );
};

// 页面加载前
onBeforeMount(() => {
  getRepairstatusEnum();
});

// 状态下拉列表初始化
const getRepairstatusEnum = () => {
  axios.apiMethod(
    "/sys/SystemData/GetRepairstatusEunm",
    "get",
    null,
    (result) => {
      console.log(result.Data);
      repairstatusOptions.value = result.Data;
    }
  );
};

const handleSizeChange = (val) => {
  repairListPadding.value.Size = val;
  repairListPadding.value.Page = 1;
};
const handleCurrentChange = (val) => {
  repairListPadding.value.Page = val;
  getRepairList();
};
// 三个单独的点击
const changeRepairStatus = (statusValue) => {
  queryAll.value = "";
  needClear.value = true;
  selectRepairStatusValue.value = statusValue;
  getRepairList();
};

const handleQuery = () => {
  repairListPadding.Page = 1;
  getRepairList();
};
</script>
  <style>
.dataTable .el-table__row {
  cursor: pointer;
}
.repairstatus_item {
  margin: 5px;
}
</style>
  